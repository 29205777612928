import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { privateRoutes } from '@constants/routes'
import { UserLanguageEnumSchema } from '@model/language'
import { AppBar, Button, Link, MenuItem, Toolbar } from '@mui/material'
import pageStyles from '@assets/css/page.module.css'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { useLanguage } from '@hooks/useLanguage'
import { useAuthContext } from '@hooks/AuthContext'
import { MenuTrigger } from '@controls/Menu/MenuTrigger'
import styles from '@layout/Page/Menu/Menu.module.css'
import menuStyles from '@layout/Page/Menu/DesktopMenu/DesktopMenu.module.css'
import { FlexContainer } from '@layout/FlexContainer'
import { LanguageMenuItemProps } from '@layout/Page'

export const AdminNav: React.FC = () => {
  const { t } = useTranslation()
  const { languageTranslation } = useEnumTranslation()
  const { logout } = useAuthContext()
  const { language, currentRoutePathForLanguage } = useLanguage()

  const handleLogoutClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    logout()
  }

  const languageItems: ReadonlyArray<LanguageMenuItemProps> = React.useMemo(
    () =>
      UserLanguageEnumSchema.options.map((lang) => ({
        title: languageTranslation(lang),
        href: currentRoutePathForLanguage(lang)
      })),
    [languageTranslation, currentRoutePathForLanguage]
  )

  return (
    <AppBar className={styles.outerContainer}>
      <Toolbar disableGutters className={clsx(styles.innerContainer, pageStyles.container)}>
        <FlexContainer fixedDirection justifyContentSpaceBetween className={menuStyles.desktopMenuContainer}>
          <FlexContainer fixedDirection alignItemsCenter noGap className={menuStyles.desktopMenuItemsContainer}>
            <NavLink to={privateRoutes.Cars}>{t('layout.admin.menu.items.cars')}</NavLink>
            <NavLink to={privateRoutes.Users}>{t('layout.admin.menu.items.users')}</NavLink>
          </FlexContainer>
          {/* language switcher */}
          <MenuTrigger
            renderMenu={() =>
              languageItems.map(({ title, href }, index) => (
                <MenuItem key={`language-${index}`}>
                  <Link href={href}>{title}</Link>
                </MenuItem>
              ))
            }
          >
            {({ openHandler }) => (
              <Button className="button-language" variant="text" onClick={(e) => openHandler(e.currentTarget)}>
                {language}
              </Button>
            )}
          </MenuTrigger>
          <Button variant="text" onClick={handleLogoutClick}>
            {t('global.buttons.logout')}
          </Button>
        </FlexContainer>
      </Toolbar>
    </AppBar>
  )
}
