import * as React from 'react'
import clsx from 'clsx'
import { CommonComponentProps } from '@controls/types'
import { ScrollToTop } from '@layout/Page/ScrollToTop/ScrollToTop'
import { AdminNav } from './AdminNav'
import styles from './AdminPage.module.css'

type Props = Readonly<{ id?: string }> & CommonComponentProps

export const AdminPage: React.FC<Props> = ({ id, className, children }) => {
  const defaultId = React.useId()
  return (
    <div id={id} className={clsx(styles.adminPage, className)}>
      <AdminNav />
      <main>{children}</main>
      <ScrollToTop id={id ?? defaultId} />
    </div>
  )
}
